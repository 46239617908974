import React, { useEffect, useState, useRef } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { State } from "country-state-city";
import { ToastError, ToastSuccess } from "../../Components/utils/ToastMessage";
function DriverSubscription(props) {
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    let states = State.getStatesOfCountry("IN");
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {

        if (props.editable) {
            Services.singleDriver("GET", null, token, props.id)
                .then((res) => {
                    console.log(res.driver);
                    if (res?.Status === 1) {
                        reset({ SubscriptionEndDate: moment(res.driver.SubscriptionEndDate).format("YYYY-MM-DD") });
                    } else if (res?.Status === 0) {
                        ToastError(res.Message);
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        // eslint-disable-next-line
    }, [props.editable, props.id, setValue, Navigate]);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };
    const openhandler = (e) => {
        e.stopPropagation();
    };

    const onSubmit = (data) => {
        let method = JSON.stringify({
            ...data,
        });
        if (props.editable) {
            Services.UpdateSubscription("PATCH", method, token, props.id)
                .then((res) => {
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.setEditable(false);
                        props.getCategories(states);
                        props.setEditable(false);
                        ToastSuccess(res.Message);
                    } else if (res.Status === 0) {
                        ToastError(res.Message)
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };
    useEffect(() => {
        const close = (e) => {
            if (e.key == "Escape") {
                console.log("close");
                props.setmodal(false);
                props.setEditable(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);
    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            <div className={dashboardclasses["modal-content"]} onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>{props.editable ? "Update Driver" : "Add "}</h3>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}
                    >
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={dashboardclasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}
                >


                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="SubscriptionEndDate">SubscriptionEndDate</label>
                        <div className={dashboardclasses["select"]}>
                            <input type="date"
                                {...register("SubscriptionEndDate", {
                                    required: "SubscriptionEndDate is required",
                                })}
                            />

                            {errors?.SubscriptionEndDate && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.SubscriptionEndDate?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={dashboardclasses["add-category-btn"]}
                        >
                            {props.editable ? "update" : "Add "}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default DriverSubscription;
