import React, { useState, useEffect } from "react";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "../../App.css";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import Addwarehouse from "./Addwarehouse";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../../Components/Layout/layout";
import { ToastError, ToastSuccess } from "../../Components/utils/ToastMessage";
import { headerstyles, override } from "./helper";
import moment from "moment";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import DriverSubscription from "./UpdateDriverSubscription";
function Drivers() {
  const Navigate = useNavigate();
  const [params] = useSearchParams();
  const [id, setId] = useState();
  const [loading, setLoading] = useState(true);
  const [pageCount, setpageCount] = useState(1);
  const [category, setCategory] = useState(false);
  const [tableLoad, setTableLoad] = useState(false);
  const [status, setStatus] = useState(
    parseInt(localStorage.getItem("activeStatus")) || 0
  );
  const [editable, setEditable] = useState(false);
  const [categoryItems, setCategoryItems] = useState([]);
  const [categorysearchItems, setCategorySearchItems] = useState([]);
  const [verifyStatus, setVerifyStatus] = useState();
  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  const categoryHandler = () => {
    setCategory((prev) => !prev);
  };

  const handleStatusButtonClick = (status) => {
    setStatus(status);
    localStorage.setItem("activeStatus", status);
    setpageCount(1);
    setTableLoad(true);
    getcategories(status);
  };

  useEffect(() => {
    getcategories(status);
    if (params.get("page")) {
      setpageCount(Number(params.get("page")));
      // console.log(typeof params.get("page"));
    }
    // eslint-disable-next-line
  }, [status]);
  useEffect(() => {
    let timer = setTimeout(() => {
      setTableLoad(false);
    }, 150);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [categorysearchItems]);

  const getcategories = (status) => {
    Services.getDrivers("GET", null, token, status)
      .then((res) => {
        setLoading(false);
        // console.log("1", res.Drivers);
        if (res.Status === 1) {
          setCategoryItems(res.Drivers);
          setCategorySearchItems(res.Drivers);
          setVerifyStatus(res.Drivers.IsDriverVerified);
        } else if (res.Status === 0) {
          ToastError(res.Message);
          if (res.Message === "Token expired") {
            Navigate("/");
            localStorage.clear();
          }
        }
      })
      .catch((err) => {
        // alert(err);
        alert("something went wrong please try again");
        console.log(err);
      });
  };

  const rowClickHandler = {
    onClick: (e, row) => {
      if (row && row.DriverID) {
        const driverId = row.DriverID;
        Navigate(`/Driver/${driverId}`);
      } else {
        console.error("Invalid row or missing DriverID");
      }
    },
  };

  const edithandler = (driverId, e, verifyStatus) => {
    e.stopPropagation();
    let body = {
      DriverID: driverId,
      IsDriverVerified: verifyStatus,
    };
    if (window.confirm("Are you sure you want to clik this Button")) {
      Services.verifyDriver("PATCH", JSON.stringify(body), token)
        .then((res) => {
          if (res.Status === 1) {
            getcategories(status);
            ToastSuccess(res.Message, "success");
          } else if (res.Status === 0) {
            ToastSuccess(res.Message, "error");
          }
          if (res.Message === "Token expired") {
            Navigate("/");
          }
        })
        .catch((err) => {
          // alert(err);
          alert("something went wrong please try again");
          console.log(err);
        });
    }
  };
  const deleteHandler = (e) => {
    e.stopPropagation();
    if (window.confirm("are you sure you want to delete ")) {
      Services.DeleteDriver(
        "DELETE",
        null,
        token,
        e.currentTarget.getAttribute("id")
      )
        .then((res) => {
          if (res.Status === 1) {
            getcategories(status);
            ToastSuccess(res.Message, "success");
          } else if (res.Status === 0) {
            ToastError(res.Message, "error");
          }
          if (res.Message === "Token expired") {
            Navigate("/");
          }
        })
        .catch((err) => {
          alert("something went wrong please try again");
          console.log(err);
        });
    }
  };
  const editHandler = (e) => {
    e.stopPropagation();
    console.log(e.currentTarget.getAttribute("id"));
    setId(e.currentTarget.getAttribute("id"));
    setEditable(true);
    setCategory(true);
  };


  const ActionFormatter = (cell, cellcontent) => {
    return (
      <div className={dashboardclasses.tablebtns}>
        {status === 0 && (
          <>
            <button
              onClick={(e) => edithandler(cellcontent.DriverID, e, 1)}
              className="btn btn-primary btn-sm "
              style={{ color: "white", backgroundColor: "#0066CC" }}
            >
              Approve
            </button>
            <button
              onClick={(e) => edithandler(cellcontent.DriverID, e, 2)}
              className="btn btn-danger btn-sm"
              style={{ color: "white", backgroundColor: "#FE4C40" }}
            >
              Reject
            </button>
          </>
        )}

        {status === 2 && (
          <>
            <button
              onClick={(e) => edithandler(cellcontent.DriverID, e, 1)}
              className="btn btn-primary btn-sm "
              style={{ color: "white", backgroundColor: "#0066CC" }}
            >
              Approve
            </button>
          </>
        )}

        {status === 1 && (
          <>
            <button
              onClick={(e) => edithandler(cellcontent.DriverID, e, 3)}
              className="btn btn-danger btn-sm"
              style={{ color: "white", backgroundColor: "#FE4C40" }}
            >
              Block
            </button>
          </>
        )}
        {status === 3 && (
          <>
            <button
              onClick={(e) => edithandler(cellcontent.DriverID, e, 0)}
              className="btn btn-success btn-sm"
              style={{ color: "white", backgroundColor: "#3AA655" }}
            >
              UnBlock
            </button>
          </>
        )}

        <button onClick={(e) => deleteHandler(e)} id={cellcontent.DriverID}>
          <MdDelete size={18} />
        </button>
      </div>
    );
  };
  let columns = [
    {
      dataField: "DriverFirstName",
      text: "DRIVER FIRSTNAME",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "130px" };
      },
    },
    {
      dataField: "DriverLastName",
      text: "DRIVER LASTNAME",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "130px" };
      },
    },
    {
      dataField: "MobileNumber",
      text: "MOBILE NUMBER",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "130px" };
      },
    },
    {
      dataField: "Gender",
      text: "GENDER",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "50px" };
      },
    },
    {
      dataField: "EmailID",
      text: "EMAILID",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "200px" };
      },
    },

    {
      dataField: "walletAmount",
      text: "WALLET AMOUNT",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "130px" };
      },
    },

    {
      dataField: "SubscriptionEndDate",
      text: "SUBSCRIPTION END DATE",
      sort: false,
      formatter: (cell, row) => {
        const Sdate = moment(cell).format('MMMM Do YYYY, h:mm:ss a');
        // console.log(cell)
        return (
          <div>
            <span>{Sdate}</span>
            <div className={dashboardclasses.tablebtns}>
              <button onClick={(e) => editHandler(e)} type="button" id={row.DriverID}>
                <FaRegEdit size={18} />
              </button>

            </div>
          </div>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "130px" };
      },
    },
    {
      dataField: "Rating",
      text: "RATING",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "100px" };
      },
    },
    {
      dataField: "ACTIONS",
      text: "ACTIONS",
      sort: false,
      formatter: ActionFormatter,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "120px" };
      },
    },
  ];
  if (status != 1) {
    columns = columns.filter(column => column.dataField != 'SubscriptionEndDate')
  }
  const pagination = paginationFactory({
    page: pageCount,
    sizePerPage: 8,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    hideSizePerPage: true,
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      window.scrollTo({ top: 10, behavior: "instant" });
      Navigate(`/Drivers?page=${page}`);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });
  const defaultSorted = [
    {
      dataField: "DriverFirstName",
      order: "desc",
    },
  ];
  const searchHandler = (e) => {
    setTableLoad(true);
    Navigate(`/Drivers?page=${1}`);
    setpageCount(1);
    const search = categoryItems.filter((item) =>
      item.MobileNumber.toLowerCase().includes(e.target.value.toLowerCase())
    );
    console.log(search);
    setCategorySearchItems(search);
  };
  const emptyDataMessage = () => {
    return "No Data to Display";
  };

  const isActiveButton = (buttonStatus) => {
    return status === buttonStatus ? dashboardclasses.active : "";
  };
  return (
    <>
      {category && (
        <DriverSubscription
          setmodal={setCategory}
          editable={editable}
          id={id}
          setEditable={setEditable}
          getCategories={() => getcategories(status)}
          setpageCount={setpageCount}
          tableload={setTableLoad}
        />
      )}

      <Layout
        className={dashboardclasses["dashboard-wrapper"]}
        Active={"Drivers"}
      >
        <div className={dashboardclasses.CategoryWrapper}>
          <h3>Drivers Information</h3>

          {loading ? (
            <div className={dashboardclasses["loader"]}>
              <ScaleLoader
                color={"black"}
                loading={true}
                css={override}
                size={150}
              />
            </div>
          ) : (
            <>
              <div className={dashboardclasses["category-form-two"]}>
                <input
                  type={"text"}
                  placeholder="search by Mobile Number"
                  onChange={searchHandler}
                />
              </div>
              <div className={dashboardclasses["category-form-three"]}>
                <button
                  className={isActiveButton(0)}
                  onClick={() => handleStatusButtonClick(0)}
                >
                  Pending
                </button>
                <button
                  className={isActiveButton(1)}
                  onClick={() => handleStatusButtonClick(1)}
                >
                  Approved
                </button>
                <button
                  className={isActiveButton(2)}
                  onClick={() => handleStatusButtonClick(2)}
                >
                  Rejected
                </button>
                <button
                  className={isActiveButton(3)}
                  onClick={() => handleStatusButtonClick(3)}
                >
                  Blocked
                </button>
              </div>
              {tableLoad ? (
                <div className={dashboardclasses["loader"]}>
                  <ScaleLoader
                    color={"black"}
                    loading={true}
                    css={override}
                    size={150}
                  />
                </div>
              ) : (
                <div className="category-table">
                  <BootstrapTable
                    bootstrap4
                    keyField="DriverID"
                    data={categorysearchItems}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    pagination={pagination}
                    rowEvents={rowClickHandler}
                    noDataIndication={emptyDataMessage}
                    wrapperClasses="table-responsive"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </Layout>
    </>
  );
}

export default Drivers;
