import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Services } from "../../Services";
import { ToastError } from "../../Components/utils/ToastMessage";
import Layout from "../../Components/Layout/layout";
import dashboardclasses from "../dashboard.module.css";
import ScaleLoader from "react-spinners/ScaleLoader";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { override } from "./helper";
const User = () => {
  const { userId } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const Navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      getCategories(userId);
    } else {
      console.error("User ID not found in URL params");
    }
  }, [userId]);

  const getCategories = (userId) => {
    Services.getUser("GET", null, token, userId)
      .then((res) => {
        setLoading(false);
        console.log(res.user);
        if (res.Status === 1) {
          setUserData(res.user);
        } else if (res.Status === 0) {
          ToastError(res.Message);
          if (res.Message === "Token expired") {
            Navigate("/");
          }
        }
      })
      .catch((err) => {
        alert("Something went wrong. Please try again.");
        console.error(err);
      });
  };

  const emptyDataMessage = () => {
    return "No Data to Display";
  };

  return (
    <>
      <Layout>
        <button
          style={{
            border: "none",
            padding: "5px",
            margin: "5px 20px",
            fontSize: "20px",
            borderBottom: "2px solid #0e9f6e",
          }}
          className="back_btn"
          onClick={() => {
            Navigate(-1);
          }}
        >
          Back
        </button>
        <div className="container mt-5">
          {loading ? (
            <div className={dashboardclasses["loader"]}>
              <ScaleLoader
                color={"black"}
                loading={true}
                css={override}
                size={150}
              />
            </div>
          ) : (
            userData && (
              <div className="card driver-card">
                <div className="card-body">
                  <h1 className="card-title">User Deatils</h1>
                  <div className="row">
                    <div className="col-md-3">
                      <img
                        src={userData.ProfilePicture}
                        alt="User Profile"
                        className="img-fluid rounded driver-image"
                      />
                    </div>
                    <div className="col-md-4">
                      <h1 className="card-title2">User Info</h1>
                      <p>
                        <strong>Name:</strong>
                        {"  "}
                        {userData.UserName}
                      </p>
                      <p>
                        <strong>Email:</strong>
                        {"  "} {userData.EmailID}
                      </p>
                      <p>
                        <strong>Mobile Number:</strong>
                        {"  "} {userData.MobileNumber}
                      </p>

                      <h2 className="card-title3">User Log info:-</h2>
                      <p>
                        <strong>UserCreatedAt:</strong>
                        {"  "}
                        {userData.CreatedAt}
                      </p>
                      <p>
                        <strong>UserCountryCode:</strong>
                        {"  "}
                        {userData.CountryCode}
                      </p>
                      <p>
                        <strong>UserStatus:</strong>
                        {"  "}
                        {userData.Status === 1
                          ? "ACTIVE"
                          : userData.Status === 2
                          ? "BLOCKED"
                          : "null"}
                      </p>
                      <p>
                        <strong>WalletAmount:</strong>
                        {"  "}
                        {userData.walletAmount}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </Layout>
    </>
  );
};

export default User;
