import React, { useState, useEffect, useLayoutEffect } from "react";
import navbarClasses from "./navbar.module.css";
import { useNavigate, NavLink } from "react-router-dom";
import { menuItems } from "./config";
import { GiHamburgerMenu } from "react-icons/gi";
import swal from "sweetalert";
import Logo from "../../Assets/roboride.jpg";
import { HiOutlineLogout } from "react-icons/hi";
import { RiArrowDropDownFill, RiArrowDropUpFill } from "react-icons/ri";
import { motion, AnimatePresence } from "framer-motion";
import useStore from "../../store";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
function Sidenav({ Active, show, setShow }) {
  const Navigate = useNavigate();
  const [NavItems, setNavItems] = useState([]);

  const dropDown = useStore((item) => item.dropdown);
  const setDropdown = useStore((item) => item.setDropdown);

  const shouldAnimate = useStore((item) => item.shouldAnimate);
  const setShouldAnimate = useStore((item) => item.setShouldAnimate);
  const handleDropdownChange = (newDropdown) => {
    if (newDropdown) {
      setShouldAnimate(true);
    } else {
      setShouldAnimate(false);
    }
  };
  const clickhandler = () => {
    setDropdown("");

    setShow((prev) => !prev);
  };
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const closeHnadler = () => {
    if (window.innerWidth <= 900 && show) {
      setShow(false);
      setDropdown("");
    }
  };
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
    closeHnadler();
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
    // eslint-disable-next-line
  }, [screenSize]);
  useLayoutEffect(() => {
    const userdata = JSON.parse(localStorage.getItem("admindata"));

    setNavItems(menuItems);
  }, []);

  const logoutHandler = () => {
    swal("Are You sure you want to Sign out?", {
      icon: "warning",
      className: navbarClasses["logout-popup"],
      dangerMode: true,
      buttons: {
        cancel: "Cancel",
        catch: {
          text: "Logout",
          value: "Logout",
          className: navbarClasses["cancel"],
        },
        defeat: false,
      },
    }).then((value) => {
      switch (value) {
        case "Logout":
          Navigate("/");
          localStorage.clear();
          break;

        // default:
        //     swal("Got away safely!");
      }
    });
  };
  return (
    <div>
      <div
        className={`${show ? navbarClasses["backdrop"] : ""}`}
        onClick={() => {
          setShow(false);
          setDropdown("");
        }}
      ></div>
      <div
        className={`${navbarClasses["Container"]} ${
          !show ? navbarClasses["hide"] : ""
        }`}
      >
        <div className={navbarClasses["hamburger"]}>
          <img
            className={!show && navbarClasses["img-size"]}
            src={Logo}
            alt="logo"
          />
          <button
            className={navbarClasses["hamburger-icon"]}
            onClick={clickhandler}
          >
            <GiHamburgerMenu size={23} color="black" />
          </button>
        </div>

        <div className={navbarClasses["navlinks"]}>
          {NavItems.map((item) => {
            if (!item.multiple) {
              return (
                <>
                  <NavLink
                    key={item.id}
                    id={item.id}
                    to={item.menuLink}
                    data-tooltip-id={item.id}
                    data-tooltip-content={item.name}
                    data-tooltip-position-strategy={"fixed"}
                    className={
                      Active === item.active
                        ? navbarClasses["navActive"]
                        : navbarClasses["navInactive"]
                    }
                  >
                    <item.icon className={navbarClasses["nav-icon"]} />
                    {show && <span>{item.name}</span>}
                  </NavLink>{" "}
                  <ReactTooltip
                    id={item.id}
                    place="left"
                    variant="info"
                    hidden={show}
                    style={{ backgroundColor: "#0e9f6e" }}
                  />
                </>
              );
            } else {
              return (
                <div className={navbarClasses["seo"]}>
                  <div
                    data-tooltip-id={item.id}
                    data-tooltip-content={item.name}
                    data-tooltip-position-strategy={"fixed"}
                    className={navbarClasses["seo-item"]}
                    onClick={(e) => {
                      e.preventDefault();
                      if (dropDown == item.name) {
                        setDropdown("");
                      } else {
                        if (!show) {
                          setShow(true);
                        }
                        setDropdown(item.name);
                      }
                    }}
                  >
                    <item.icon
                      data-tooltip-id={item.id}
                      data-tooltip-content={item.name}
                      data-tooltip-position-strategy={"fixed"}
                      className={navbarClasses["nav-icon"]}
                    />
                    <ReactTooltip
                      id={item.id}
                      place="left"
                      variant="info"
                      hidden={show}
                      style={{
                        backgroundColor: "#0e9f6e",
                      }}
                    />
                    {show && (
                      <p>
                        {item.name}{" "}
                        {dropDown == item.name ? (
                          <RiArrowDropUpFill size={30} />
                        ) : (
                          <RiArrowDropDownFill size={30} />
                        )}{" "}
                      </p>
                    )}
                  </div>
                  <AnimatePresence initial={!(dropDown == item.name)}>
                    {dropDown == item.name && (
                      <motion.div
                        key={dropDown}
                        initial={{ height: 0 }}
                        animate={{ height: "auto" }}
                        exit={{ height: 0 }}
                        transition={{ duration: 0.5 }}
                        onAnimationComplete={() => {
                          setShouldAnimate(false);
                        }}
                        className={`${navbarClasses["seo-item-nav"]} ${
                          shouldAnimate ? "animate-class" : ""
                        }`}
                      >
                        {item.items.map((links, id) => (
                          <NavLink
                            key={id}
                            id={1}
                            to={links.menuLink}
                            className={`${
                              Active === links.active
                                ? navbarClasses["navActive"]
                                : navbarClasses["navInactive"]
                            } ${!show ? navbarClasses["sub-link-hide"] : ""}`}
                          >
                            {show && (
                              <span title={links.name}>{links.name}</span>
                            )}
                          </NavLink>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              );
            }
          })}
        </div>
        <button className={navbarClasses["logout"]} onClick={logoutHandler}>
          <HiOutlineLogout size={23} title="Layout" />
          {show && <p>Logout</p>}
        </button>
      </div>
    </div>
  );
}

export default Sidenav;
