import React, { useEffect, useRef, useState } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { State } from "country-state-city";
import { ToastError } from "../../Components/utils/ToastMessage";
function EditSubscription(props) {
  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  //   let states = State.getStatesOfCountry("IN");
  const [value, setValue] = useState();
  const Navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (props.editable) {
      Services.SubscriptionsByID("GET", null, token, props.id)
        .then((res) => {
          console.log(res.subscription);
          if (res?.Status === 1) {
            const formData = {
              SubscriptionID: res.SubscriptionID,
              VehicleType: res.VehicleType,
              Amount: res.Amount,
              Plan: res.Plan,
            };

            reset(res.subscription);
          } else if (res?.Status === 0) {
            ToastError(res.Message);
            if (res.Message === "Token expired") {
              Navigate("/");
            }
          }
        })
        .catch((err) => {
          alert("something went wrong please try again");
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, [props.editable, props.id, setValue, Navigate]);

  const CancelHandler = (e) => {
    e.stopPropagation();
    props.setmodal(false);
    props.setEditable(false);
  };
  const openhandler = (e) => {
    e.stopPropagation();
  };

  const onSubmit = (data) => {
    console.log(data);

    let method = JSON.stringify({
      ...data,
    });
    if (!props.editable) {
      props.tableload(true);
      Services.AddSubscription("POST", method, token)
        .then((res) => {
          if (res.Status === 1) {
            Navigate(`/Plans?page=${1}`);
            props.setpageCount(1);
            props.setmodal(false);
            props.getCategories();
            props.tableload(false);
            toast.success(`${res.Message}`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (res.Status === 0) {
            props.tableload(false);
            toast.error(`${res.Message}`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            if (res.Message === "Token expired") {
              Navigate("/");
              localStorage.clear();
            }
          }
        })
        .catch((err) => {
          props.tableload(false);
          alert("something went wrong please try again");
          console.log(err);
        });
    } else if (props.editable) {
      Services.EditSubscriptionsByID("PATCH", method, token, props.id)
        .then((res) => {
          if (res.Status === 1) {
            props.setmodal(false);
            props.setEditable(false);
            props.getCategories();
            props.setEditable(false);
            toast.success(`${res.Message}`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (res.Status === 0) {
            toast.error(`${res.Message}`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            if (res.Message === "Token expired") {
              Navigate("/");
            }
          }
        })
        .catch((err) => {
          // alert(err);
          alert("something went wrong please try again");
          console.log(err);
        });
    }
  };
  useEffect(() => {
    const close = (e) => {
      if (e.key == "Escape") {
        console.log("close");
        props.setmodal(false);
        props.setEditable(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  return (
    <div className={dashboardclasses.modal} onClick={CancelHandler}>
      <div className={dashboardclasses["modal-content"]} onClick={openhandler}>
        <div className={dashboardclasses["modal-content-header"]}>
          <div>
            <h3>
              {props.editable ? "Update Subscription" : "Add Subscription"}
            </h3>
          </div>
          <button
            className={dashboardclasses["close-btn"]}
            onClick={CancelHandler}
          >
            <IoClose size={22} color={"red"} />
          </button>
        </div>
        <form
          className={dashboardclasses["add-items"]}
          onSubmit={handleSubmit(onSubmit)}
        >
          {props.editable && (
            <div className={dashboardclasses["item-image-upload"]}>
              <label htmlFor="SubscriptionID">SubscriptionID</label>
              <div className={dashboardclasses["select"]}>
                <input
                  {...register("SubscriptionID", {
                    required: "SubscriptionID is required",
                  })}
                  disabled
                />
                {errors?.SubscriptionID && (
                  <p className={dashboardclasses["error"]}>
                    {errors?.SubscriptionID?.message}
                  </p>
                )}
              </div>
            </div>
          )}
          <div className={dashboardclasses["item-image-upload"]}>
            <label htmlFor="VehicleType">VehicleType</label>
            <div className={dashboardclasses["select"]}>
              <select
                defaultValue=""
                {...register("VehicleType", {
                  required: "VehicleType is required",
                })}
              >
                <option value="" disabled>
                  Select VehicleType
                </option>
                <option value={1}>Auto</option>
                <option value={2}>Car</option>
                <option value={3}>Bike</option>
                <option value={4}>Driver</option>
                <option value={5}>Lady Driver</option>
                <option value={0}>All Vehicles</option>
              </select>
              {errors?.VehicleType && (
                <p className={dashboardclasses["error"]}>
                  {errors?.VehicleType?.message}
                </p>
              )}
            </div>
          </div>
          <div className={dashboardclasses["item-image-upload"]}>
            <label htmlFor="Amount">Amount</label>
            <div className={dashboardclasses["select"]}>
              <input
                type="number"
                {...register("Amount", {
                  required: "Amount is required",
                })}
              />
              {errors?.Amount && (
                <p className={dashboardclasses["error"]}>
                  {errors?.Amount?.message}
                </p>
              )}
            </div>
          </div>
          <div className={dashboardclasses["item-image-upload"]}>
            <label htmlFor="PlanDuration">PlanDuration</label>
            <div className={dashboardclasses["select"]}>
              <input
                type="text"
                {...register("PlanDuration", {
                  required: "PlanDuration is required",
                })}
              />
              {errors?.PlanDuration && (
                <p className={dashboardclasses["error"]}>
                  {errors?.PlanDuration?.message}
                </p>
              )}
            </div>
          </div>
          <div className={dashboardclasses["item-image-upload"]}>
            <label htmlFor="PlanName"> PlanName</label>
            <div className={dashboardclasses["select"]}>
              <input
                type="text"
                {...register("PlanName", {
                  required: "PlanName is required",
                })}
              />
               
              {errors?.PlanName && (
                <p className={dashboardclasses["error"]}>
                  {errors?.PlanName?.message}
                </p>
              )}
            </div>
          </div>
          <div className={dashboardclasses["item-image-upload"]}>
            <label htmlFor="NumberOfDays"> NumberOfDays</label>
            <div className={dashboardclasses["select"]}>
              <input
                type="number"
                {...register("NumberOfDays", {
                  required: " Number Of Days  is required",
                })}
              />
              {errors?.NumberOfDays && (
                <p className={dashboardclasses["error"]}>
                  {errors?.NumberOfDays?.message}
                </p>
              )}
            </div>
          </div>
          {props.editable && (
            <div className={dashboardclasses["item-image-upload"]}>
              <label htmlFor="Status">Status</label>
              <div className={dashboardclasses["select"]}>
                <select
                  defaultValue=""
                  {...register("Status", {
                    required: "Status is required",
                  })}
                >
                  <option value="" disabled>
                    Select Status
                  </option>
                  <option value={1}>Active</option>
                  <option value={0}>InActive</option>
                </select>
                {errors?.Status && (
                  <p className={dashboardclasses["error"]}>
                    {errors?.Status?.message}
                  </p>
                )}
              </div>
            </div>
          )}

          <div className={dashboardclasses["add-btn"]}>
            <button type="button" onClick={CancelHandler}>
              Cancel
            </button>
            <button
              type="submit"
              className={dashboardclasses["add-category-btn"]}
            >
              {props.editable ? "update" : "Add "}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditSubscription;
