console.log(process.env.REACT_APP_S3_ACCESS_KEY);

const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  dirName: "banners",
  region: "ap-south-1",
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
};
export default config;
