import React, { useState, useEffect } from "react";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "../../App.css";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../../Components/Layout/layout";
import { ToastError, ToastSuccess } from "../../Components/utils/ToastMessage";
import { headerstyles, override } from "../AdminPages/helper";
import EditSubscription from "./EditSubscription";
function SubscriptionPlans() {
  const Navigate = useNavigate();
  const [params] = useSearchParams();
  const [id, setId] = useState();
  const [loading, setLoading] = useState(true);
  const [pageCount, setpageCount] = useState(1);
  const [status, setStatus] = useState(1);
  const [category, setCategory] = useState(false);
  const [tableLoad, setTableLoad] = useState(false);
  const [editable, setEditable] = useState(false);
  const [categoryItems, setCategoryItems] = useState([]);
  const [categorysearchItems, setCategorySearchItems] = useState([]);
  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const categoryHandler = () => {
    setCategory((prev) => !prev);
  };

  useEffect(() => {
    getcategories();
    if (params.get("page")) {
      setpageCount(Number(params.get("page")));
      // console.log(typeof params.get("page"));
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (token) {
      getcategories();
    } else {
      Navigate("/");
    }
  }, []);
  useEffect(() => {
    let timer = setTimeout(() => {
      setTableLoad(false);
    }, 150);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [categorysearchItems]);

  const getcategories = () => {
    Services.SubscriptionPlans("GET", null, token)
      .then((res) => {
        setLoading(false);
        // console.log("1", res.users);
        if (res.Status === 1) {
          setCategoryItems(res.SubscriptionPlans);
          setCategorySearchItems(res.SubscriptionPlans);
        } else if (res.Status === 0) {
          ToastError(res.Message);
          if (res.Message === "Token expired") {
            Navigate("/");
          }
        }
      })
      .catch((err) => {
        // alert(err);
        alert("something went wrong please try again");
        console.log(err);
      });
  };

  //   const rowClickHandler = {
  //     onClick: (e, row) => {
  //       if (row && row.UserID) {
  //         const userId = row.UserID;
  //         Navigate(`/User/${userId}`);
  //       } else {
  //         console.error("Invalid row or missing UserID");
  //       }
  //     },
  //   };
  const edithandler = (e) => {
    console.log(e.currentTarget.getAttribute("id"));
    setId(e.currentTarget.getAttribute("id"));
    setEditable(true);
    setCategory(true);
  };

  const ActionFormatter = (cell, cellcontent) => {
    console.log(cellcontent);
    return (
      <div className={dashboardclasses.tablebtns}>
        <button onClick={edithandler} id={cellcontent.SubscriptionID}>
          <FaRegEdit size={18} />
        </button>
        {/* <button onClick={deleteHandler} id={cellcontent.CouponCodeID}>
          <MdDelete size={18} />
        </button> */}
      </div>
    );
  };

  const columns = [
    {
      dataField: "SubscriptionID",
      text: "SUBSCRIPTION ID",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "130px" };
      },
    },
    {
      dataField: "VehicleType",
      text: "VEHICLE TYPE",
      sort: false,
      formatter: (cell) => {
        let vehicleName = "";

        switch (cell) {
          case "1":
            vehicleName = "Auto";
            break;
          case "2":
            vehicleName = "Car";
            break;
          case "3":
            vehicleName = "Bike";
            break;
          case "4":
            vehicleName = "Driver";
            break;
          case "5":
            vehicleName = "Lady Driver";
            break;
          default:
            vehicleName = "All Vehicles";
            break;
        }
        return vehicleName;
      },
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "150px" };
      },
    },
    {
      dataField: "Amount",
      text: "AMOUNT",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "100px" };
      },
    },
    {
      dataField: "PlanDuration",
      text: "PLAN DURATION",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "150px" };
      },
    },
    {
      dataField: "PlanName",
      text: "PLAN NAME",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "100px" };
      },
    },
    {
      dataField: "NumberOfDays",
      text: "NUMBER OF DAYS",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "150px" };
      },
    },

    {
      dataField: "ACTIONS",
      text: "ACTIONS",
      sort: false,
      formatter: ActionFormatter,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "100px" };
      },
    },
  ];
  const pagination = paginationFactory({
    page: pageCount,
    sizePerPage: 8,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    hideSizePerPage: true,
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      window.scrollTo({ top: 10, behavior: "instant" });
      Navigate(`/Plans?page=${page}`);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });
  const defaultSorted = [
    {
      dataField: "SubscriptionID",
      order: "desc",
    },
  ];
  // const searchHandler = (e) => {
  //   setTableLoad(true);
  //   Navigate(`/Plans?page=${1}`);
  //   setpageCount(1);
  //   const search = categoryItems.filter((item) =>
  //     item.MobileNumber.toLowerCase().includes(e.target.value.toLowerCase())
  //   );
  //   console.log(search);
  //   setCategorySearchItems(search);
  // };
  const emptyDataMessage = () => {
    return "No Data to Display";
  };

  //   const isActiveButton = (buttonStatus) => {
  //     return status === buttonStatus ? dashboardclasses.active : "";
  //   };
  return (
    <>
      {category && (
        <EditSubscription
          setmodal={setCategory}
          editable={editable}
          id={id}
          setEditable={setEditable}
          getCategories={getcategories}
          setpageCount={setpageCount}
          tableload={setTableLoad}
        />
      )}
      <Layout
        className={dashboardclasses["dashboard-wrapper"]}
        Active={"Subscription Plans"}
      >
        <div className={dashboardclasses.CategoryWrapper}>
          <h3>Subscription Plans</h3>

          {loading ? (
            <div className={dashboardclasses["loader"]}>
              <ScaleLoader
                color={"black"}
                loading={true}
                css={override}
                size={150}
              />
            </div>
          ) : (
            <>
              {/* <div className={dashboardclasses["category-form-two"]}>
                <input
                  type={"text"}
                  placeholder="Search by Mobile Number"
                  onChange={searchHandler}
                />
                <button onClick={categoryHandler}>+ Add Subscription</button>
              </div> */}

              {tableLoad ? (
                <div className={dashboardclasses["loader"]}>
                  <ScaleLoader
                    color={"black"}
                    loading={true}
                    css={override}
                    size={150}
                  />
                </div>
              ) : (
                <div className="category-table">
                  <BootstrapTable
                    bootstrap4
                    keyField="SubscriptionID"
                    data={categorysearchItems}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    pagination={pagination}
                    noDataIndication={emptyDataMessage}
                    wrapperClasses="table-responsive"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </Layout>
    </>
  );
}

export default SubscriptionPlans;
