import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./Components/utils/scrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Login from "./Pages/Login/login";
import { useEffect, useState } from "react";
import { Gaurd } from "./Components/Gaurd";
import Bookings from "./Pages/AdminPages/Bookings";
import Drivers from "./Pages/AdminPages/Drivers";
import Driver from "./Pages/AdminPages/Driver";
import Users from "./Pages/AdminPages/Users";
import User from "./Pages/AdminPages/User";
import Booking from "./Pages/AdminPages/Booking";
import TransportModes from "./Pages/AdminPages/TransportModes";
import TransportMode from "./Pages/AdminPages/TransportMode";
import Coupons from "./Pages/AdminPages/Coupons";
import Coupon from "./Pages/AdminPages/Coupon";
import FAQs from "./Pages/AdminPages/FAQs";
import Config from "./Pages/AdminPages/Config";
import SubscriptionPlans from "./Pages/Subscriptions/SubscriptionPlans";
import MySubscriptions from "./Pages/Subscriptions/MySubscriptions";
import Banners from "./Pages/Banners/Banners";

function App() {
  const [isLogin, setIsLogin] = useState(false);
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setIsLogin(true);
    }
  }, []);
  return (
    <>
      <ToastContainer />
      <ScrollToTop>
        <Routes>
          <Route exaact path="/" element={<Login />} />
          <>
            <Route exaact path="/Bookings" element={<Bookings />} />
            <Route exaact path="/Booking/:bookingId" element={<Booking />} />
            <Route exaact path="/Drivers" element={<Drivers />} />
            <Route exaact path="/Driver/:driverId" element={<Driver />} />
            <Route exaact path="/User/:userId" element={<User />} />
            <Route
              exaact
              path="/Users"
              element={<Gaurd element={<Users />} />}
            />
            <Route
              exaact
              path="/UserSubsCriptions"
              element={<MySubscriptions />}
            />
            <Route exaact path="/Plans" element={<SubscriptionPlans />} />
            <Route exaact path="/Transports" element={<TransportModes />} />
            <Route
              exaact
              path="/Transports/:transportId"
              element={<TransportMode />}
            />
            <Route exaact path="/Coupons" element={<Coupons />} />
            <Route exaact path="/Coupons/:couponID" element={<Coupon />} />
            <Route exaact path="/Faqs" element={<FAQs />} />
            <Route exaact path="/Config" element={<Config />} />
            <Route exaact path="/Banner"  element={<Banners/>}/>
          </>
        </Routes>
      </ScrollToTop>
    </>
  );
}

export default App;
