import uuid from "react-uuid";

import { FaUsers } from "react-icons/fa";
import { GrStatusInfo } from "react-icons/gr";
import { MdEmojiTransportation } from "react-icons/md";
import { RiCoupon3Line } from "react-icons/ri";
import { FaQuoteRight } from "react-icons/fa";
import { GrDocumentConfig } from "react-icons/gr";
import { MdSubscriptions } from "react-icons/md";
import { SiSubstack } from "react-icons/si";
// import { PiFlagBannerFill } from "react-icons/pi";
// import{PiUserListFill} from 'react-icons/pi'
import { GiUsaFlag } from "react-icons/gi";
import { FaCarAlt } from "react-icons/fa";
export const menuItems = [
  {
    id: uuid(),
    multiple: false,
    name: "Users",
    menuLink: "/Users?page=1",
    active: "Users",
    icon: FaUsers,
  },
  {
    id: uuid(),
    multiple: false,
    name: "Subscription Plans",
    menuLink: "/Plans?page=1",
    active: "Subscription Plans",
    icon: MdSubscriptions,
  },
  {
    id: uuid(),
    multiple: false,
    name: "User Subscriptions",
    menuLink: "/UserSubsCriptions?page=1",
    active: "User Subscriptions",
    icon: SiSubstack,
  },
  {
    id: uuid(),
    multiple: false,
    name: "Bookings",
    menuLink: "/Bookings?page=1",
    active: "Bookings",
    icon: GrStatusInfo,
  },

  {
    id: uuid(),
    multiple: false,
    name: "Drivers",
    menuLink: "/Drivers?page=1",
    active: "Drivers",
    icon: FaCarAlt,
  },
  {
    id: uuid(),
    multiple: false,
    name: "TransportModes",
    menuLink: "/Transports?page=1",
    active: "TransportModes",
    icon: MdEmojiTransportation,
  },
  {
    id: uuid(),
    multiple: false,
    name: "Coupons",
    menuLink: "/Coupons?page=1",
    active: "Coupons",
    icon: RiCoupon3Line,
  },
  {
    id: uuid(),
    multiple: false,
    name: "Config",
    menuLink: "/Config?page=1",
    active: "Config",
    icon: GrDocumentConfig,
  },
  {
    id: uuid(),
    multiple: false,
    name: "FAQS",
    menuLink: "/Faqs?page=1",
    active: "FAQS",
    icon: FaQuoteRight,
  },
  {
    id: uuid(),
    multiple: false,
    name: "Banners",
    menuLink: "/Banner?page=1",
    active: "Banners",
    icon: GiUsaFlag,
  },
];
