import React, { useEffect, useRef, useState } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { State } from "country-state-city";
import { ToastError, ToastSuccess } from "../../Components/utils/ToastMessage";
import S3FileUpload from "react-s3";
import { FileUploader } from "react-drag-drop-files";
// import Config from "../Banners/config";
import config from "../Banners/config";
// import { LuUpload } from "react-icons/lu";

window.Buffer = window.Buffer || require("buffer").Buffer;
function AddBanner(props) {
  console.log(config);
  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  //   let states = State.getStatesOfCountry("IN");
  //   const [value, setValue] = useState();
  const [imageUrl, setImageUrl] = useState();
  const Navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (props.editable) {
      Services.BannerById("GET", null, token, props.id)
        .then((res) => {
          console.log(res.subscription);
          if (res?.Status === 1) {
            const formData = {
              BannerID: res.BannerID,
              Type: res.Type,
              Status: res.Status,
            };

            reset(res.banners);
          } else if (res?.Status === 0) {
            ToastError(res.Message);
            if (res.Message === "Token expired") {
              Navigate("/");
            }
          }
        })
        .catch((err) => {
          alert("something went wrong please try again");
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, [props.editable, props.id, setValue, Navigate]);

  const CancelHandler = (e) => {
    e.stopPropagation();
    props.setmodal(false);
    props.setEditable(false);
  };
  const openhandler = (e) => {
    e.stopPropagation();
  };

  const onSubmit = (data) => {
    console.log(data);

    let method = JSON.stringify({
      ...data,
    });
    if (!props.editable) {
      props.tableload(true);
      Services.AddBanner("POST", method, token)
        .then((res) => {
          if (res.Status === 1) {
            Navigate(`/Banner?page=${1}`);
            props.setpageCount(1);
            props.setmodal(false);
            props.getCategories();
            props.tableload(false);
            ToastSuccess(res.Message);
          } else if (res.Status === 0) {
            props.tableload(false);
            ToastError(res.Message);
            if (res.Message === "Token expired") {
              Navigate("/");
              localStorage.clear();
            }
          }
        })
        .catch((err) => {
          props.tableload(false);
          alert("something went wrong please try again");
          console.log(err);
        });
    } else if (props.editable) {
      Services.EditBanner("PATCH", method, token, props.id)
        .then((res) => {
          if (res.Status === 1) {
            props.setmodal(false);
            props.setEditable(false);
            props.getCategories();
            props.setEditable(false);
            ToastSuccess(res.Message);
          } else if (res.Status === 0) {
            ToastError(res.Message);
            if (res.Message === "Token expired") {
              Navigate("/");
            }
          }
        })
        .catch((err) => {
          // alert(err);
          alert("something went wrong please try again");
          console.log(err);
        });
    }
  };
  useEffect(() => {
    const close = (e) => {
      if (e.key == "Escape") {
        console.log("close");
        props.setmodal(false);
        props.setEditable(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const fileUploadHandler = (event) => {
    const file = event.target.files[0];
    // return;
    const type = file.type;

    if (type === "image/png" || type === "image/jpg" || type === "image/jpeg") {
      const newFileName = `${new Date().getTime()}.${type.split("/")[1]}`;
      console.log(newFileName);
      // return;
      const Modifiedfile = new File([file], newFileName);
      console.log(file);
      S3FileUpload.uploadFile(Modifiedfile, config)
        .then((data) => {
          const fileName = data.location.split("/").pop();
          setImageUrl(data.location);
          console.log(data);

          setValue("Banner", fileName);
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <div className={dashboardclasses.modal} onClick={CancelHandler}>
      <div className={dashboardclasses["modal-content"]} onClick={openhandler}>
        <div className={dashboardclasses["modal-content-header"]}>
          <div>
            <h3>{props.editable ? "Update Banner" : "Add Banner"}</h3>
          </div>
          <button
            className={dashboardclasses["close-btn"]}
            onClick={CancelHandler}
          >
            <IoClose size={22} color={"red"} />
          </button>
        </div>
        <form
          className={dashboardclasses["add-items"]}
          onSubmit={handleSubmit(onSubmit)}
        >
          {props.editable && (
            <div className={dashboardclasses["item-image-upload"]}>
              <label htmlFor="BannerID">BannerID</label>
              <div className={dashboardclasses["select"]}>
                <input
                  {...register("BannerID", {
                    required: "BannerID is required",
                  })}
                  disabled
                />
                {errors?.BannerID && (
                  <p className={dashboardclasses["error"]}>
                    {errors?.BannerID?.message}
                  </p>
                )}
              </div>
            </div>
          )}
          <div className={dashboardclasses["item-image-upload"]}>
            <label htmlFor="Type">Type</label>
            <div className={dashboardclasses["select"]}>
              <select
                defaultValue=""
                {...register("Type", {
                  required: "Type is required",
                })}
              >
                <option value="" disabled>
                  Select Type
                </option>
                <option value={1}>users</option>
                <option value={2}>drivers</option>
              </select>
              {errors?.Type && (
                <p className={dashboardclasses["error"]}>
                  {errors?.Type?.message}
                </p>
              )}
            </div>
          </div>
          {props.editable && (
            <div className={dashboardclasses["item-image-upload"]}>
              <label htmlFor="Status">Status</label>
              <div className={dashboardclasses["select"]}>
                <select
                  defaultValue=""
                  {...register("Status", {
                    required: "Status is required",
                  })}
                >
                  <option value="" disabled>
                    Select Status
                  </option>
                  <option value={1}>Active</option>
                  <option value={0}>InActive</option>
                </select>
                {errors?.Status && (
                  <p className={dashboardclasses["error"]}>
                    {errors?.Status?.message}
                  </p>
                )}
              </div>
            </div>
          )}

          <div className={dashboardclasses["item-image-upload"]}>
            <label htmlFor="Banner">Banner</label>
            <div className={dashboardclasses["select"]}>
              <input type="file" onChange={fileUploadHandler} />
              {errors?.Banner && (
                <p className={dashboardclasses["error"]}>
                  {errors?.Banner?.message}
                </p>
              )}
            </div>
          </div>

          <div className={dashboardclasses["add-btn"]}>
            <button type="button" onClick={CancelHandler}>
              Cancel
            </button>
            <button
              type="submit"
              className={dashboardclasses["add-category-btn"]}
            >
              {props.editable ? "update" : "Add "}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddBanner;
