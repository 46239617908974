// Authorization: `token ${token}`,
const httpcall = (url, method, body, token) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
    method: method,
    body: body,
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      ...token,
    },
  }).then((response) => response.json());
};

const login = (method, body) => {
  return httpcall("admin/Login ", method, body);
};

//-----------------------------------------------Booking Status--------------------------

const bookingStatus = (method, body, token, status) => {
  return httpcall(`admin/getBookings/${status}`, method, body, token);
};

const bookingByID = (method, body, token, bookingId) => {
  return httpcall(`admin/getBookingbyID/${bookingId}`, method, body, token);
};

//----------------------------------------------- Users --------------------------------
const getUsers = (method, body, token) => {
  return httpcall("admin/getUsers", method, body, token);
};

const getUser = (method, body, token, userId) => {
  return httpcall(`admin/getUser/${userId}`, method, body, token);
};

const updateUser = (method, body, token) => {
  return httpcall(`admin/upadateUserStatus`, method, body, token);
};
// ..............................Subscription Plans ............................

const SubscriptionPlans = (method, body, token) => {
  return httpcall("admin/getsubscriptionplans", method, body, token);
};

const MySubscriptionPlans = (method, body, token) => {
  return httpcall("admin/getuserssubscriptions", method, body, token);
};

const SubscriptionsByID = (method, body, token, id) => {
  return httpcall(`admin/getsubscriptionplanbyId/${id}`, method, body, token);
};

const EditSubscriptionsByID = (method, body, token, id) => {
  return httpcall(`admin/updateSubscription/${id}`, method, body, token);
};

const AddSubscription = (method, body, token) => {
  return httpcall("admin/addSubscription", method, body, token);
};
//----------------------------------------------- Drivers --------------------------------

const getDrivers = (method, body, token, status) => {
  return httpcall(
    `admin/getDriversByApproveStatus/${status}`,
    method,
    body,
    token
  );
};

const singleDriver = (method, body, token, driverId) => {
  return httpcall(`admin/getDriver/${driverId}`, method, body, token);
};
const verifyDriver = (method, body, token) => {
  return httpcall(`admin/verifydriver`, method, body, token);
};
const verifyDocument = (method, body, token) => {
  return httpcall(`admin/verifydocs`, method, body, token);
};
const verifyVehicle = (method, body, token) => {
  return httpcall(`admin/verifyvehicle`, method, body, token);
};

const DeleteDriver = (method, body, token, id) => {
  return httpcall(`admin/deleteDriver/${id}`, method, body, token);
};

const UpdateSubscription = (method, body, token, id) => {
  return httpcall(`admin/updateDriverSubscription/${id}`, method, body, token);
};

//----------------------------------------------- Trasport Modes --------------------------------
const getTransportModes = (method, body, token) => {
  return httpcall(`admin/Get_Transport_Modes`, method, body, token);
};
const getTransportByID = (method, body, token, transportId) => {
  return httpcall(
    `admin/Transport_Mode_Details/${transportId}`,
    method,
    body,
    token
  );
};
const addTransport = (method, body, token) => {
  return httpcall("admin/Add_Transport_Modes", method, body, token);
};

const updateTransport = (method, body, token, transportId) => {
  return httpcall(
    `admin/Update_Transport_Modes/${transportId}`,
    method,
    body,
    token
  );
};

//----------------------------------------------- Coupons ------------------------------------
const AddCoupon = (method, body, token) => {
  return httpcall(`admin/Add_Coupon`, method, body, token);
};

const getCoupons = (method, body, token) => {
  return httpcall(`admin/Get_Coupons`, method, body, token);
};

const getCoupon = (method, body, token, couponID) => {
  return httpcall(`admin/Get_Coupon/${couponID}`, method, body, token);
};
const updateCoupon = (method, body, token, couponID) => {
  return httpcall(`admin/Update_Coupon/${couponID}`, method, body, token);
};
const deleteCoupon = (method, body, token, couponID) => {
  return httpcall(`admin/Delete_Coupon/${couponID}`, method, body, token);
};

//----------------------------------------------- FAQ ------------------------------------
const AddFaQ = (method, body, token) => {
  return httpcall(`admin/Add_FAQ`, method, body, token);
};
const getFaQs = (method, body, token) => {
  return httpcall(`admin/Get_FAQ`, method, body, token);
};
const getFaQ = (method, body, token, customerID) => {
  return httpcall(`admin/Get_FAQById/${customerID}`, method, body, token);
};
const updateFaQ = (method, body, token, customerID) => {
  return httpcall(`admin/Update_FAQ/${customerID}`, method, body, token);
};

const deleteFaQ = (method, body, token, customerID) => {
  return httpcall(`admin/deleteFAQ/${customerID}`, method, body, token);
};
//----------------------------------------------- Configer ------------------------------------
const AddConfig = (method, body, token) => {
  return httpcall("admin/addconfig", method, body, token);
};
const getConfigByID = (method, body, token, configId) => {
  return httpcall(`admin/getconfigById/${configId}`, method, body, token);
};
const getConfig = (method, body, token) => {
  return httpcall("admin/getconfig", method, body, token);
};
const UpdateConfig = (method, body, token, configId) => {
  return httpcall(`admin/updateconfig/${configId}`, method, body, token);
};

// Banners

const Banners = (method, body, token) => {
  return httpcall("admin/getBanners", method, body, token);
};

const BannerById = (method, body, token, BannerID) => {
  return httpcall(`admin/getBannersById/${BannerID}`, method, body, token);
};

const EditBanner = (method, body, token, BannerID) => {
  return httpcall(`admin/updateBanner/${BannerID}`, method, body, token);
};

const AddBanner = (method, body, token) => {
  return httpcall("admin/addBanners", method, body, token);
};
const DeleteBanner = (method, body, token, id) => {
  return httpcall(`admin/deleteBanner/${id}`, method, body, token);
};

export const Services = {
  login,
  getUsers,
  getUser,
  updateUser,
  getDrivers,
  verifyDriver,
  verifyDocument,
  verifyVehicle,
  singleDriver,
  bookingStatus,
  bookingByID,
  addTransport,
  updateTransport,
  getTransportModes,
  getTransportByID,
  getCoupons,
  AddCoupon,
  updateCoupon,
  deleteCoupon,
  getCoupon,
  getFaQ,
  AddFaQ,
  getFaQs,
  deleteFaQ,
  updateFaQ,
  AddConfig,
  getConfig,
  getConfigByID,
  UpdateConfig,
  SubscriptionPlans,
  MySubscriptionPlans,
  SubscriptionsByID,
  EditSubscriptionsByID,
  AddSubscription,
  Banners,
  BannerById,
  EditBanner,
  AddBanner,
  DeleteBanner,
  DeleteDriver,
  UpdateSubscription,
};
