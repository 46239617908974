import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Services } from "../../Services";
import { ToastError, ToastSuccess } from "../../Components/utils/ToastMessage";
import Layout from "../../Components/Layout/layout";
import dashboardclasses from "../dashboard.module.css";
import ScaleLoader from "react-spinners/ScaleLoader";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { override } from "./helper";
import { Modal, Button } from "react-bootstrap";

// ... (imports and other code)

const Driver = () => {
  const { driverId } = useParams();
  const [driverData, setDriverData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [documentId, setDocumentId] = useState(null);
  const [vehicleId, setVehicleId] = useState(null);
  const [driverDocuments, setDriverDocuments] = useState([]);
  const [driverVehicles, setDriverVehicles] = useState([]);
  const [verifyDocuments, setVerifyDocumnets] = useState();
  const [verifyVehicles, setVerifyVehicles] = useState();
  const [showModal, setShowModal] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageModalUrl, setImageModalUrl] = useState("");
  const [rejectVehicleReason, setRejectVehicleReason] = useState("");
  const [rejectDocumentId, setRejectDocumentId] = useState(null);
  const [rejectVechicleId, setRejectVechicleId] = useState(null);
  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  const Navigate = useNavigate();

  useEffect(() => {
    if (driverId) {
      getDriverData(driverId);
    } else {
      console.error("Driver ID not found in URL params");
    }
  }, [driverId]);

  const handleRejectModal = (documentId) => {
    setRejectDocumentId(documentId);
    setShowModal(true);
  };
  const handleRejectVehicleModal = (vehicleId) => {
    setRejectVechicleId(vehicleId);
    setShowModal(true);
  };
  const handleCloseModalBox = () => {
    setRejectVechicleId(null);
    setRejectVehicleReason("");
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setRejectDocumentId(null);
    setRejectReason("");
    setShowModal(false);
  };

  const getDriverData = (driverId) => {
    Services.singleDriver("GET", null, token, driverId)
      .then((res) => {
        setLoading(false);
        if (res.Status === 1) {
          setDriverData(res.driver);
          setDocumentId(res.driver.DriverDocumentID);
          setVehicleId(res.driver.DriverVehicleID);
          setRejectReason(res.driverdocumets.RejectReason);
          setRejectVehicleReason(res.drivervehicles.RejectVehicleReason);
          setDriverDocuments(res.driverdocumets || []);
          setDriverVehicles(res.drivervehicles || []);
        } else if (res.Status === 0) {
          ToastError(res.Message);
          if (res.Message === "Token expired") {
            Navigate("/");
          }
        }
      })
      .catch((err) => {
        alert("Something went wrong. Please try again.");
        console.error(err);
      });
  };

  const handleVerifyDriver = (driverId) => {
    let body = {
      DriverID: driverId,
      IsDriverVerified: 1,
    };
    Services.verifyDriver("PATCH", JSON.stringify(body), token)
      .then((res) => {
        if (res.Status === 1) {
          setDriverData((prevDocuments) =>
            prevDocuments.map((doc) =>
              doc.DriverID === driverId ? { ...doc, IsDriverVerified: 1 } : doc
            )
          );
          console.log("Driver document verified successfully");
        } else {
          ToastError(res.Message);
        }
      })
      .catch((err) => {
        alert("Something went wrong. Please try again.");
        console.error(err);
      });
  };

  const handleVerifyDocument = (documentId, verifyDocuments) => {
    let body;

    if (verifyDocuments === 2) {
      body = {
        DriverID: driverId,
        IsDocumentVerified: verifyDocuments,
        DriverDocumentID: documentId,
        RejectReason: rejectReason,
      };
    } else {
      body = {
        DriverID: driverId,
        IsDocumentVerified: verifyDocuments,
        DriverDocumentID: documentId,
      };
    }
    Services.verifyDocument("PATCH", JSON.stringify(body), token)
      .then((res) => {
        if (res.Status === 1) {
          setDriverDocuments((prevDocuments) =>
            prevDocuments.map((doc) =>
              doc.DriverDocumentID === documentId
                ? { ...doc, IsDocumentVerified: verifyDocuments }
                : doc
            )
          );

          console.log(
            "Document Rejected with Comment:",
            rejectReason,
            documentId
          );
          setShowModal(false);
          setRejectReason(" ");
          ToastSuccess(res.Message);
        } else {
          ToastError(res.Message);
        }
      })
      .catch((err) => {
        alert("Something went wrong. Please try again.");
        console.error(err);
      });
  };

  const handleVerifyVehicle = (vehicleId, verifyVehicles) => {
    let body;
    if (verifyVehicles === 2) {
      body = {
        IsVehicleVerified: verifyVehicles,
        DriverVehicleID: vehicleId,
        RejectVehicleReason: rejectVehicleReason,
      };
    } else {
      body = {
        IsVehicleVerified: verifyVehicles,
        DriverVehicleID: vehicleId,
      };
    }
    Services.verifyVehicle("PATCH", JSON.stringify(body), token)
      .then((res) => {
        if (res.Status === 1) {
          setDriverVehicles((prevVehicles) =>
            prevVehicles.map((vehicle) =>
              vehicle.DriverVehicleID === vehicleId
                ? { ...vehicle, IsVehicleVerified: verifyVehicles }
                : vehicle
            )
          );
          setRejectVehicleReason(" ");
          setShowModal(false);
          ToastSuccess(res.Message);
          console.log("Vehicle Rejected ", rejectVehicleReason);
          console.log("Driver vehicle verified successfully");
        } else {
          ToastError(res.Message);
        }
      })
      .catch((err) => {
        alert("Something went wrong. Please try again.");
        console.error(err);
      });
  };

  const handleOpenImageModal = (imageUrl) => {
    setImageModalUrl(imageUrl);
    setShowImageModal(true);
  };

  return (
    <>
      <Layout>
        <button
          style={{
            border: "none",
            padding: "5px",
            margin: "5px 20px",
            fontSize: "20px",
            borderBottom: "2px solid #0e9f6e",
          }}
          className="back_btn"
          onClick={() => {
            Navigate(-1);
          }}
        >
          Back
        </button>
        <div className="container mt-5">
          {loading ? (
            <div className={dashboardclasses["loader"]}>
              <ScaleLoader
                color={"black"}
                loading={true}
                css={override}
                size={150}
              />
            </div>
          ) : (
            driverData && (
              <div className="card driver-card">
                <div className="card-body">
                  <h1 className="card-title">Driver Details</h1>
                  <div className="row">
                    <div className="col-md-3">
                      <img
                        src={driverData.ProfilePicture}
                        alt="Driver Profile"
                        className="img-fluid rounded driver-image"
                      />
                    </div>
                    <div className="col-md-4">
                      <h1 className="card-title2">
                        Driver Info : {"  "}
                        {driverData.IsDriverVerified === 0
                          ? "PENDING"
                          : driverData.IsDriverVerified === 1
                          ? "APPROVED"
                          : driverData.IsDriverVerified === 2
                          ? "REJECTED"
                          : driverData.IsDriverVerified === 3
                          ? "BLOCKED"
                          : "null"}
                        {/* {driverData.IsDriverVerified === 2 && (
                          <button
                            className="btn btn-primary btn-sm"
                            style={{
                              padding: "5px 10px",
                              margin: "5px",
                            }}
                            onClick={() =>
                              handleVerifyDriver(driverData.DriverID)
                            }
                          >
                            Approve
                          </button>
                        )} */}
                      </h1>
                      <p>
                        <strong>Name:</strong>
                        {"  "}
                        {`${driverData.DriverFirstName} ${driverData.DriverLastName}`}
                      </p>
                      <p>
                        <strong>Email:</strong>
                        {"  "} {driverData.EmailID}
                      </p>
                      <p>
                        <strong>Mobile Number:</strong>
                        {"  "}
                        {driverData.MobileNumber}
                      </p>
                      <p>
                        <strong>Gender:</strong>
                        {"  "} {driverData.Gender}
                      </p>
                      <p>
                        <strong>Vechile Type:</strong>
                        {"  "}
                        {driverData.DriverVehicleType === 1
                          ? "Auto"
                          : driverData.DriverVehicleType === 2
                          ? "Car"
                          : driverData.DriverVehicleType === 3
                          ? "Bike"
                          : driverData.DriverVehicleType === 4
                          ? "driver"
                          : driverData.DriverVehicleType === 5
                          ? "lady driver"
                          : "NoVehicle"}
                      </p>
                      <p>
                        <strong>Ratings:</strong>
                        {"  "} {driverData.Rating}
                      </p>
                    </div>
                    <div className="col-md-0">
                      <h2 className="card-title3">
                        Driver Documentation info:-
                      </h2>
                      <div className="container">
                        <div
                          className="table-responsive "
                          style={{ maxHeight: "400px", overflowY: "auto" }}
                        >
                          <table
                            className="table table-bordered"
                            style={{ maxWidth: "800px" }}
                          >
                            <thead className="thead-light">
                              <tr>
                                <th>ID Proof</th>
                                <th>ID Number</th>
                                <th>Photo</th>
                                <th>Status</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {driverDocuments.map((document, index) => (
                                <tr key={index}>
                                  <td>{document.Id_Proof}</td>
                                  <td>{document.Id_Number}</td>
                                  <td>
                                    <img
                                      src={document.Photo}
                                      alt="Photo"
                                      className="img-fluid rounded doc-image"
                                      onClick={() =>
                                        handleOpenImageModal(document.Photo)
                                      }
                                    />
                                  </td>
                                  <td>
                                    {document.IsDocumentVerified === 1 ? (
                                      <p>Verified</p>
                                    ) : document.IsDocumentVerified === 2 ? (
                                      <p>Rejected</p>
                                    ) : (
                                      <p>Pending</p>
                                    )}
                                  </td>
                                  <td>
                                    {(document.IsDocumentVerified === 0 
                                    // || document.IsDocumentVerified === 2
                                      ) && (
                                        <>
                                      <button
                                        className="btn btn-primary btn-sm"
                                        style={{
                                          padding: "5px 10px",
                                          margin: "5px",
                                        }}
                                        onClick={() =>
                                          handleVerifyDocument(
                                            document.DriverDocumentID,
                                            1
                                          )
                                        }
                                      >
                                        Approve
                                      </button>
                                      <button
                                          className="btn btn-danger btn-sm"
                                          style={{
                                            padding: "5px 10px",
                                            margin: "5px",
                                          }}
                                          onClick={() =>
                                            handleRejectModal(
                                              document.DriverDocumentID
                                            )
                                          }
                                        >
                                          Reject
                                        </button>
                                      {/* </>

                                      
                                    )}
                                    {document.IsDocumentVerified === 1 && (
                                      <>
                                        <button
                                          className="btn btn-danger btn-sm"
                                          style={{
                                            padding: "5px 10px",
                                            margin: "5px",
                                          }}
                                          onClick={() =>
                                            handleRejectModal(
                                              document.DriverDocumentID
                                            )
                                          }
                                        >
                                          Reject
                                        </button> */}

                                        <Modal
                                          show={
                                            showModal &&
                                            document.DriverDocumentID ===
                                              rejectDocumentId
                                          }
                                          onHide={() =>
                                            handleCloseModal(
                                              document.DriverDocumentID
                                            )
                                          }
                                        >
                                          <Modal.Header closeButton>
                                            <Modal.Title>
                                              Enter Rejection Comment
                                            </Modal.Title>
                                          </Modal.Header>
                                          <Modal.Body>
                                            <label htmlFor="rejectReason">
                                              RejectReason:
                                            </label>
                                            <textarea
                                              id="rejectReason"
                                              className="form-control"
                                              value={rejectReason}
                                              onChange={(e) =>
                                                setRejectReason(e.target.value)
                                              }
                                              rows="4"
                                            />
                                          </Modal.Body>
                                          <Modal.Footer>
                                            <Button
                                              variant="secondary"
                                              onClick={() =>
                                                setShowModal(false)
                                              }
                                            >
                                              Close
                                            </Button>
                                            <Button
                                              variant="danger"
                                              onClick={() =>
                                                handleVerifyDocument(
                                                  document.DriverDocumentID,
                                                  2
                                                )
                                              }
                                              disabled={!rejectReason?.trim()}
                                            >
                                              Submit
                                            </Button>
                                          </Modal.Footer>
                                        </Modal>
                                      </>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {driverData.DriverVehicleType !== 4 &&
                        driverData.DriverVehicleType !== 5 && (
                          <>
                            {" "}
                            <h2 className="card-title3">
                              Driver Vehicle info:-
                            </h2>
                            <div className="container">
                              <div
                                className="table-responsive"
                                style={{
                                  maxHeight: "400px",
                                  overflowY: "auto",
                                }}
                              >
                                <table
                                  className="table table-bordered"
                                  style={{ maxWidth: "1000px" }}
                                >
                                  <thead className="thead-light">
                                    <tr>
                                      <th>DriverVehicleType</th>
                                      <th>LicensePlate</th>
                                      <th>Model</th>
                                      <th>VehicleBrand</th>
                                      <th>RcImage</th>
                                      <th>InsuranceImage</th>
                                      <th>Year</th>
                                      <th>Status</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {driverVehicles.map((vehicle, index) => (
                                      <tr key={index}>
                                        <td>
                                          {vehicle.DriverVehicleType === 1
                                            ? "Auto"
                                            : vehicle.DriverVehicleType === 2
                                            ? "Car"
                                            : vehicle.DriverVehicleType === 3
                                            ? "Bike"
                                            : vehicle.DriverVehicleType === 4
                                            ? "driver"
                                            : vehicle.DriverVehicleType === 5
                                            ? "lady driver"
                                            : "NoVehicle"}
                                        </td>
                                        <td>{vehicle.LicensePlate}</td>
                                        <td>{vehicle.Model}</td>
                                        <td>{vehicle.VehicleBrand}</td>
                                        <td>
                                          <img
                                            src={vehicle.rcImage}
                                            alt="Photo"
                                            className="img-fluid rounded doc-image"
                                            onClick={() =>
                                              handleOpenImageModal(
                                                vehicle.rcImage
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <img
                                            src={vehicle.InsuranceImage}
                                            alt="Photo"
                                            className="img-fluid rounded doc-image"
                                            onClick={() =>
                                              handleOpenImageModal(
                                                vehicle.InsuranceImage
                                              )
                                            }
                                          />
                                        </td>

                                        <td>{vehicle.Year}</td>
                                        <td>
                                          {vehicle.IsVehicleVerified === 1 ? (
                                            <p>Verified</p>
                                          ) : vehicle.IsVehicleVerified ===
                                            2 ? (
                                            <p>Rejected</p>
                                          ) : (
                                            <p>Pending</p>
                                          )}
                                        </td>
                                        <td>
                                          {(vehicle.IsVehicleVerified === 0 
                                          // ||vehicle.IsVehicleVerified ===2
                                          ) && (
                                                <>
                                            <button
                                              className="btn btn-primary btn-sm"
                                              style={{
                                                padding: "5px 10px",
                                                margin: "5px",
                                              }}
                                              onClick={() =>
                                                handleVerifyVehicle(
                                                  vehicle.DriverVehicleID,
                                                  1
                                                )
                                              }
                                            >
                                              Approve
                                            </button>
                                          {/* )}
                                          {vehicle.IsVehicleVerified === 1 && (
                                            <> */}
                                              <button
                                                className="btn btn-danger btn-sm"
                                                style={{
                                                  padding: "5px 10px",
                                                  margin: "5px",
                                                }}
                                                onClick={() =>
                                                  handleRejectVehicleModal(
                                                    vehicle.DriverVehicleID
                                                  )
                                                }
                                              >
                                                Reject
                                              </button>

                                              <Modal
                                                show={
                                                  showModal &&
                                                  vehicle.DriverVehicleID ===
                                                    rejectVechicleId
                                                }
                                                onHide={() =>
                                                  handleCloseModalBox(
                                                    vehicle.DriverVehicleID
                                                  )
                                                }
                                              >
                                                <Modal.Header closeButton>
                                                  <Modal.Title>
                                                    Enter Rejection Comment
                                                  </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                  <label htmlFor="rejectVehicleReason">
                                                    RejectReason:
                                                  </label>
                                                  <textarea
                                                    id="rejectVehicleReason"
                                                    className="form-control"
                                                    value={rejectVehicleReason}
                                                    onChange={(e) =>
                                                      setRejectVehicleReason(
                                                        e.target.value
                                                      )
                                                    }
                                                    rows="4"
                                                  />
                                                </Modal.Body>
                                                <Modal.Footer>
                                                  <Button
                                                    variant="secondary"
                                                    onClick={() =>
                                                      setShowModal(false)
                                                    }
                                                  >
                                                    Close
                                                  </Button>
                                                  <Button
                                                    variant="danger"
                                                    onClick={() =>
                                                      handleVerifyVehicle(
                                                        vehicle.DriverVehicleID,
                                                        2
                                                      )
                                                    }
                                                    disabled={
                                                      !rejectVehicleReason?.trim()
                                                    }
                                                  >
                                                    Submit
                                                  </Button>
                                                </Modal.Footer>
                                              </Modal>
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>

        <Modal show={showImageModal} onHide={() => setShowImageModal(false)}>
          <Modal.Body>
            <img
              src={imageModalUrl}
              alt="Full Image"
              className="img-fluid"
              style={{ maxWidth: "100%" }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowImageModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </>
  );
};

export default Driver;
