import React, { useEffect, useRef, useState } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { State } from "country-state-city";
function TransportUpdate(props) {
  const token = { Authorization: `token ${localStorage.getItem("token")}` };
  //   let states = State.getStatesOfCountry("IN");
  const [value, setValue] = useState();
  const Navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (props.editable) {
      Services.getTransportByID("GET", null, token, props.id)
        .then((res) => {
          console.log(res.TransPortTypes);
          if (res?.Status === 1) {
            const formData = {
              TransportName: res.TransportName,
              ImageURL: res.ImageURL,
              Status: res.Status != null ? res.Status.toString() : "",
              baseFareKm:
                res.baseFareKm != null
                  ? res.baseFareKm.toString()
                  : "",
              baseFareAmount:
                res.baseFareAmount != null ? res.baseFareAmount.toString() : "",

              pricePerKmUptoEightKm:
                res.pricePerKmUptoEightKm != null ? res.pricePerKmUptoEightKm.toString() : "",

              additionalPricePerKmAfterEightKm:
                res.additionalPricePerKmAfterEightKm != null ? res.additionalPricePerKmAfterEightKm.toString() : "",

              No_Of_Persons:
                res.No_Of_Persons != null ? res.No_Of_Persons.toString() : "",
              TransportVehicleType:
                res.TransportVehicleType != null
                  ? res.TransportVehicleType.toString()
                  : "",
            };

            reset(res.TransPortTypes[0]);
          } else if (res?.Status === 0) {
            toast.error(`${res.Message}`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            if (res.Message === "Token expired") {
              Navigate("/");
            }
          }
        })
        .catch((err) => {
          alert("something went wrong please try again");
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, [props.editable, props.id, setValue, Navigate]);

  const CancelHandler = (e) => {
    e.stopPropagation();
    props.setmodal(false);
    props.setEditable(false);
  };
  const openhandler = (e) => {
    e.stopPropagation();
  };

  const onSubmit = (data) => {
    // console.log(data);

    let method = JSON.stringify({
      ...data,
    });
    if (!props.editable) {
      props.tableload(true);
      Services.addTransport("POST", method, token)
        .then((res) => {
          if (res.Status === 1) {
            Navigate(`/Transports?page=${1}`);
            props.setpageCount(1);
            props.setmodal(false);
            props.getCategories();
            props.tableload(false);
            toast.success(`${res.Message}`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (res.Status === 0) {
            props.tableload(false);
            toast.error(`${res.Message}`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            if (res.Message === "Token expired") {
              Navigate("/");
            }
          }
        })
        .catch((err) => {
          props.tableload(false);
          alert("something went wrong please try again");
          console.log(err);
        });
    } else if (props.editable) {
      Services.updateTransport("PATCH", method, token, props.id)
        .then((res) => {
          if (res.Status === 1) {
            props.setmodal(false);
            props.setEditable(false);
            props.getCategories();
            props.setEditable(false);
            toast.success(`${res.Message}`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (res.Status === 0) {
            toast.error(`${res.Message}`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            if (res.Message === "Token expired") {
              Navigate("/");
            }
          }
        })
        .catch((err) => {
          // alert(err);
          alert("something went wrong please try again");
          console.log(err);
        });
    }
  };
  useEffect(() => {
    const close = (e) => {
      if (e.key == "Escape") {
        console.log("close");
        props.setmodal(false);
        props.setEditable(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  return (
    <div className={dashboardclasses.modal} onClick={CancelHandler}>
      <div className={dashboardclasses["modal-content"]} onClick={openhandler}>
        <div className={dashboardclasses["modal-content-header"]}>
          <div>
            <h3>
              {props.editable ? "Update Transport Modes" : "Add Transport"}
            </h3>
          </div>
          <button
            className={dashboardclasses["close-btn"]}
            onClick={CancelHandler}
          >
            <IoClose size={22} color={"red"} />
          </button>
        </div>
        <form
          className={dashboardclasses["add-items"]}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={dashboardclasses["item-image-upload"]}>
            <label htmlFor="TransportName">Transport Name</label>
            <div className={dashboardclasses["select"]}>
              <input
                {...register("TransportName", {
                  required: "Transport Name is required",
                })}
              />
              {errors?.TransportName && (
                <p className={dashboardclasses["error"]}>
                  {errors?.TransportName?.message}
                </p>
              )}
            </div>
          </div>
          <div className={dashboardclasses["item-image-upload"]}>
            <label htmlFor="ImageURL">Image URL</label>
            <div className={dashboardclasses["select"]}>
              <input {...register("ImageURL")} />
            </div>
          </div>

          <div className={dashboardclasses["item-image-upload"]}>
            <label htmlFor="baseFareKm">Base FareKm</label>
            <div className={dashboardclasses["select"]}>
              <input type="number" {...register("baseFareKm")} />
              {errors?.baseFareKm && (
                <p className={dashboardclasses["error"]}>
                  {errors?.baseFareKm?.message}
                </p>
              )}
            </div>
          </div>
          <div className={dashboardclasses["item-image-upload"]}>
            <label htmlFor="baseFareAmount">BaseFare Amount</label>
            <div className={dashboardclasses["select"]}>
              <input
                type="number"
                {...register("baseFareAmount", {
                  required: "BaseFare Amount is required",
                })}
              />
              {errors?.baseFareAmount && (
                <p className={dashboardclasses["error"]}>
                  {errors?.baseFareAmount?.message}
                </p>
              )}
            </div>
          </div>
          <div className={dashboardclasses["item-image-upload"]}>
            <label htmlFor="pricePerKmUptoEightKm">PricePerKm Upto EightKm</label>
            <div className={dashboardclasses["select"]}>
              <input
                type="number"
                step="any"
                {...register("pricePerKmUptoEightKm", {
                  required: "PricePerKm Upto EightKm is required",
                })}
              />
              {errors?.pricePerKmUptoEightKm && (
                <p className={dashboardclasses["error"]}>
                  {errors?.pricePerKmUptoEightKm?.message}
                </p>
              )}
            </div>
          </div>

          <div className={dashboardclasses["item-image-upload"]}>
            <label htmlFor="additionalPricePerKmAfterEightKm">Additional PricePer AfterEightKm</label>
            <div className={dashboardclasses["select"]}>
              <input
                type="number"
                step="any"
                {...register("additionalPricePerKmAfterEightKm", {
                  required: "Additional PricePerKm AfterEightKm is required",
                })}
              />
              {errors?.additionalPricePerKmAfterEightKm && (
                <p className={dashboardclasses["error"]}>
                  {errors?.additionalPricePerKmAfterEightKm?.message}
                </p>
              )}
            </div>
          </div>

          <div className={dashboardclasses["item-image-upload"]}>
            <label htmlFor="No_Of_Persons">Number of Persons</label>
            <div className={dashboardclasses["select"]}>
              <input
                type="number"

                {...register("No_Of_Persons", {
                  required: "Number of Persons is required",
                  pattern: {
                    value: /^[1-9]\d*$/,
                    message: "Invalid Number"
                  }
                })}
              />
              {errors?.No_Of_Persons && (
                <p className={dashboardclasses["error"]}>
                  {errors?.No_Of_Persons?.message}
                </p>
              )}
            </div>
          </div>
          <div className={dashboardclasses["item-image-upload"]}>
            <label htmlFor="TransportVehicleType">Transport Vehicle Type</label>
            <div className={dashboardclasses["select"]}>
              <select
                defaultValue=""
                {...register("TransportVehicleType", {
                  required: "TransportVehicleType is required",
                })}
              >
                <option value="" disabled>
                  Select VehicleType
                </option>
                <option value={1}>Auto</option>
                <option value={2}>Car</option>
                <option value={3}>Bike</option>
                <option value={4}>Driver</option>
                <option value={5}>Lady Driver</option>
              </select>
              {errors?.TransportVehicleType && (
                <p className={dashboardclasses["error"]}>
                  {errors?.TransportVehicleType?.message}
                </p>
              )}
            </div>
          </div>
          <div className={dashboardclasses["item-image-upload"]}>
            <label htmlFor="Status">Status</label>
            <div className={dashboardclasses["select"]}>
              <select
                defaultValue=""
                {...register("Status", {
                  required: "Status is required",
                })}
              >
                <option value="" disabled>
                  Select Status
                </option>
                <option value={1}>Active</option>
                <option value={0}>Inactive</option>
              </select>
              {errors?.Status && (
                <p className={dashboardclasses["error"]}>
                  {errors?.Status?.message}
                </p>
              )}
            </div>
          </div>
          <div className={dashboardclasses["add-btn"]}>
            <button type="button" onClick={CancelHandler}>
              Cancel
            </button>
            <button
              type="submit"
              className={dashboardclasses["add-category-btn"]}
            >
              {props.editable ? "update" : "Add Transport"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TransportUpdate;
