import React, { useState, useEffect } from "react";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "../../App.css";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../../Components/Layout/layout";
import { ToastError, ToastSuccess } from "../../Components/utils/ToastMessage";
import { headerstyles, override } from "./helper";
import TransportUpdate from "./TransportUpdate";
function TransportModes() {
  const Navigate = useNavigate();
  const [id, setId] = useState();
  const [params] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [pageCount, setpageCount] = useState(1);
  const [category, setCategory] = useState(false);
  const [tableLoad, setTableLoad] = useState(false);
  //   const [status, setStatus] = useState(2);
  const [editable, setEditable] = useState(false);
  const [categoryItems, setCategoryItems] = useState([]);
  const [categorysearchItems, setCategorySearchItems] = useState([]);

  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  const categoryHandler = () => {
    setCategory((prev) => !prev);
  };

  useEffect(() => {
    getcategories();
    if (params.get("page")) {
      setpageCount(Number(params.get("page")));
      // console.log(typeof params.get("page"));
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    let timer = setTimeout(() => {
      setTableLoad(false);
    }, 150);
    return () => {
      clearTimeout(timer);
    };
  }, [categorysearchItems]);

  const getcategories = () => {
    Services.getTransportModes("GET", null, token)
      .then((res) => {
        setLoading(false);
        console.log("1", res.TransPortTypes);
        if (res.Status === 1) {
          setCategoryItems(res.TransPortTypes);
          setCategorySearchItems(res.TransPortTypes);
        } else if (res.Status === 0) {
          ToastError(res.Message);
          if (res.Message === "Token expired") {
            Navigate("/");
          }
        }
      })
      .catch((err) => {
        // alert(err);
        alert("something went wrong please try again");
        console.log(err);
      });
  };

  const rowClickHandler = {
    onClick: (e, row) => {
      if (row && row.TransportModeID) {
        const transportId = row.TransportModeID;
        Navigate(`/Transports/${transportId}`);
      } else {
        console.error("Invalid row or missing TransportModeID");
      }
    },
  };

  const edithandler = (e) => {
    e.stopPropagation();
    console.log(e.currentTarget.getAttribute("id"));
    setId(e.currentTarget.getAttribute("id"));
    setEditable(true);
    setCategory(true);
  };

  const ActionFormatter = (cell, cellcontent) => {
    console.log(cellcontent);
    return (
      <div className={dashboardclasses.tablebtns}>
        <button
          onClick={(e) => edithandler(e)}
          id={cellcontent.TransportModeID}
        >
          <FaRegEdit size={18} />
        </button>
        {/* <button onClick={deleteHandler} id={cellcontent.warehouseId}>
            <MdDelete size={18} />
          </button> */}
      </div>
    );
  };
  const statusFormatter = (cell, cellcontent) => {
    let classname =
      cell === 1
        ? dashboardclasses["status_active"]
        : dashboardclasses["status_inactive"];
    return (
      <span className={classname} style={{ textAlign: "center" }}>
        {cell === 1 ? "Active" : "Inactive"}
      </span>
    );
  };

  const columns = [
    {
      dataField: "TransportName",
      text: "TRANSPORT NAME",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "200px" };
      },
    },
    {
      dataField: "TransportVehicleType",
      text: "VEHICLE TYPE",
      sort: false,
      formatter: (cell) => {
        let vehicleName = "";
        switch (cell) {
          case 1:
            vehicleName = "Auto";
            break;
          case 2:
            vehicleName = "Car";
            break;
          case 3:
            vehicleName = "Bike";
            break;
          case 4:
            vehicleName = "Driver";
            break;
          case 5:
            vehicleName = "Lady Driver";
            break;
          default:
            vehicleName = "Unknown";
            break;
        }
        return vehicleName;
      },
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "150px" };
      },
    },

    {
      dataField: "No_Of_Persons",
      text: "NO_OF_PERSONS",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "150px" };
      },
    },
    {
      dataField: "baseFareKm",
      text: "BASEFARE KM",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "100px" };
      },
    },
    {
      dataField: "baseFareAmount",
      text: "BASEFARE AMOUNT",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "150px" };
      },
    },
    {
      dataField: "pricePerKmUptoEightKm",
      text: "PRICE PERKM UP TO EIGHTKM ",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "150px" };
      },
    },
    {
      dataField: "additionalPricePerKmAfterEightKm",
      text: "ADDITIONAL PRICE PERKM",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "150px" };
      },
    },

    {
      dataField: "Status",
      text: "STATUS",
      sort: false,
      formatter: statusFormatter,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "100px" };
      },
    },
    {
      dataField: "ACTIONS",
      text: "ACTIONS",
      sort: false,
      formatter: ActionFormatter,
      headerStyle: (colum, colIndex) => {
        return { ...headerstyles, width: "100px" };
      },
    },
  ];
  const pagination = paginationFactory({
    page: pageCount,
    sizePerPage: 8,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    hideSizePerPage: true,
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      window.scrollTo({ top: 10, behavior: "instant" });
      Navigate(`/Transports?page=${page}`);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });
  const defaultSorted = [
    {
      dataField: "TransportName",
      order: "desc",
    },
  ];
  const searchHandler = (e) => {
    setTableLoad(true);
    Navigate(`/Transports?page=${1}`);
    setpageCount(1);
    const search = categoryItems.filter((item) =>
      item.TransportName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    console.log(search);
    setCategorySearchItems(search);
  };
  const emptyDataMessage = () => {
    return "No Data to Display";
  };

  return (
    <>
      {category && (
        <TransportUpdate
          setmodal={setCategory}
          editable={editable}
          id={id}
          setEditable={setEditable}
          getCategories={getcategories}
          setpageCount={setpageCount}
          tableload={setTableLoad}
        />
      )}

      <Layout
        className={dashboardclasses["dashboard-wrapper"]}
        Active={"TransportModes"}
      >
        <div className={dashboardclasses.CategoryWrapper}>
          <h3>Transport Modes</h3>

          {loading ? (
            <div className={dashboardclasses["loader"]}>
              <ScaleLoader
                color={"black"}
                loading={true}
                css={override}
                size={150}
              />
            </div>
          ) : (
            <>
              <div className={dashboardclasses["category-form-two"]}>
                <input
                  type={"text"}
                  placeholder="search by Transport Name"
                  onChange={searchHandler}
                />

                {/* <button onClick={categoryHandler}>+ Add Transport</button> */}
              </div>

              {tableLoad ? (
                <div className={dashboardclasses["loader"]}>
                  <ScaleLoader
                    color={"black"}
                    loading={true}
                    css={override}
                    size={150}
                  />
                </div>
              ) : (
                <div className="category-table">
                  <BootstrapTable
                    bootstrap4
                    keyField="TransportModeID"
                    data={categorysearchItems}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    pagination={pagination}
                    rowEvents={rowClickHandler}
                    noDataIndication={emptyDataMessage}
                    wrapperClasses="table-responsive"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </Layout>
    </>
  );
}

export default TransportModes;
